import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useHeaderControls } from '@fs/zion-root'
import { colors, Divider } from '@fs/zion-ui'
import { Link, useLocation, useRouteMatch } from '@fs/zion-router'
import { useTranslation } from 'react-i18next'
import { i18n } from '@fs/zion-locale'
import { css } from '@emotion/core'
import { boolAttr, useResizeObserver } from '@fs/zion-frontend-friends'
import Subnav from '@fs/zion-tree-subnav-legacy'
import { DarkModeContext } from '../../DarkModeProvider'

const navBarCss = css`
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  white-space: nowrap;
  scroll-behavior: smooth;
  line-height: 2.5rem;
  margin-inline-start: 10px;
  flex-shrink: 0;

  & a:hover {
    text-decoration: none;
  }

  /* Hide menu when printing */
  @media print {
    display: none;
  }

  /* Hide menu when in full screen mode */
  transition: 0.3s ease;
  &[hide] {
    transform: translateY(calc(-1 * var(--navigation-tabs-height)));
    margin-bottom: calc(-1 * var(--navigation-tabs-height));
  }
`

const noUnderlineCss = css`
  text-decoration: none;
`

const untranslatedBooksLangs = [
  'zh',
  'zh-hans',
  'ja',
  'ko',
  'th',
  'da',
  'hu',
  'nl',
  'pl',
  'sv',
  'sk',
  'fi',
  'mn',
  'bg',
  'km',
  'id',
  'ro',
  'sm',
  'to',
  'vi',
  'fj',
  'sq',
  'cs',
  'no',
  'ar',
]

const getIndex = ({ pathname, genArkMatch, t }) => {
  if (genArkMatch) return 2

  // this array helps us find out which nav link is selected or highlighted
  const pages = [
    '/images',
    '/tree',
    '/genealogies',
    '/catalog',
    '/library/books',
    t('search.tab.wikiLink', '/wiki/en/Main_Page'),
  ]
  const startIndex = pages.findIndex((page) => pathname.includes(page))
  // default to a higher index, used to highlight the records tab if path doesn't match anything else
  return startIndex > 0 ? startIndex : 6
}

function NavLink({ path, name, external, index, selectedIndex }) {
  const { theme } = useContext(DarkModeContext)

  const spanCss = css`
    font-family: Verdana, Ayuthaya, sans-serif;
    color: ${colors.gray100};
    text-decoration: none;
    background: transparent;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    font-size: 14px;
    margin-inline-end: 5px;
    padding: 4px 10px;
    vertical-align: middle;

    [lang|='zh'] &,
    [lang|='ja'] &,
    [lang|='ko'] & {
      font-size: 16px;
    }

    &:hover {
      background: ${theme === 'night' ? colors.green90 : colors.green03};
      color: ${theme === 'night' ? colors.gray00 : colors.gray100};
      text-decoration: underline; /* required or else the <a> tag will underline with a color */
    }
    &:focus {
      outline: none;
    }
    &[is-selected] {
      background: ${theme === 'night' ? colors.green90 : colors.green03};
      color: ${theme === 'night' ? colors.gray00 : colors.gray100};
    }
  `
  return (
    <Link noLinkStyles to={path} external={external} wayfinding css={noUnderlineCss}>
      <span css={spanCss} index={index} is-selected={boolAttr(selectedIndex === index)}>
        {name}
      </span>
    </Link>
  )
}

export default function NavigationTabs({ navRef }) {
  const { pathname, search } = useLocation()

  return (
    <div ref={navRef}>
      {pathname.includes('tree') && search.includes('from=tree') ? <Subnav page="find" /> : <NavigationTabsInner />}
    </div>
  )
}

function NavigationTabsInner() {
  const { t } = useTranslation()
  // Books and Wiki are hidden for CJK languages at the request of FS CJK specialists
  const hideBooksLangs = untranslatedBooksLangs.indexOf(i18n.language) >= 0
  const { pathname } = useLocation()
  const genArkMatch = useRouteMatch('/ark:/61903/2::arkPath')

  const [selectedIndex, setSelectedIndex] = useState(() => getIndex({ pathname, genArkMatch, t }))

  // If the header goes into full screen mode, gracefully hide the header
  const headerControls = useHeaderControls()
  const headerContentRef = useRef()
  const setHeaderHeight = useCallback(() => {
    // We use this CSS variable in the "hide header" transition
    document.documentElement.style.setProperty(
      '--navigation-tabs-height',
      `${headerContentRef.current?.clientHeight}px`
    )
  }, [])
  useResizeObserver(headerContentRef, setHeaderHeight)

  useEffect(() => {
    setSelectedIndex(getIndex({ pathname, genArkMatch, t }))
  }, [pathname, genArkMatch, t])

  function navLink({ path, name, index, external = true }) {
    return <NavLink path={path} name={name} index={index} selectedIndex={selectedIndex} external={external} />
  }

  return (
    <>
      <nav
        aria-label={t('search.tab.secondary', 'Secondary')}
        css={navBarCss}
        hide={boolAttr(!headerControls || (headerControls && !headerControls.isVisible))}
        ref={headerContentRef}
      >
        {navLink({
          path: '/search',
          name: t('search.tab.records', 'Records'),
          index: 6,
          external: true,
        })}
        {navLink({ path: '/records/images', name: t('search.tab.images', 'Images'), index: 0 })}
        {navLink({
          path: '/search/tree',
          name: t('search.tab.tree', 'Family Tree'),
          index: 1,
          external: true,
        })}
        {navLink({
          path: '/search/genealogies',
          name: t('search.tab.genealogies', 'Genealogies'),
          index: 2,
          external: true,
        })}
        {navLink({ path: '/search/catalog', name: t('search.tab.catalog', 'Catalog'), index: 3, external: true })}
        {!hideBooksLangs &&
          navLink({
            path: 'https://www.familysearch.org/library/books/',
            name: t('search.tab.books', 'Books'),
            index: 4,
          })}
        {!hideBooksLangs &&
          navLink({
            path: t('search.tab.wikiLink', '/wiki/en/Main_Page'),
            name: t('search.tab.wiki', 'Wiki'),
            index: 5,
          })}
      </nav>
      <Divider />
    </>
  )
}
