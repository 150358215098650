import React from 'react'
import { css } from '@emotion/core'
import { NoticeLoading } from '@fs/zion-icon'

const loadingPositionCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export default function CenteredSpinner() {
  return (
    <div css={loadingPositionCss}>
      <NoticeLoading size="lg" />
    </div>
  )
}
