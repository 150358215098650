import { sgBaseUrl } from '@fs/zion-config'

// use a reducer to handle the state of all responses from the call to filmdatainfo
export const filmDataReducerInitialState = {
  mode: null,

  collectionContext: null,

  dgsNum: null,

  isWaypoint: false,

  imageData: { records: [], collections: [] },

  // The reducer will have either waypointData or filmData, but not both
  waypointData: [],
  filmData: {},

  images: [],
  p200Images: [],
  noImageIndexes: null,
  viewedImages: new Set(),

  loading: false,
  error: null,
  loadingWaypoints: true,

  // once the user has entered DGS mode, we want to keep them in DGS mode (hide waypoints and show film number instead)
  hasEnteredDgsMode: false,
  saveBottomSheetHeight: true,

  // timestamp of the last image info call
  lastImageInfoTimestamp: Date.now(),

  spinOut: false,
}

/**
 * The reducer for useFilmData
 * @param {*} state the current state
 * @param {*} action the action to perform
 * @returns state with the updated values from action
 */
export default function filmDataReducer(state, action) {
  if (action.isCancelled) return state

  switch (action.type) {
    case 'SET_LOADING': {
      const newState = {
        loading: true,
        loadingWaypoints: !state.waypointData,
        mode: action.mode,
        hasEnteredDgsMode: action.mode === 'dgs' || state.hasEnteredDgsMode,
      }
      if (action.mode === 'ark') {
        newState.imageData = { records: [], collections: [] }
      }
      return updateReducerLiterals(state, newState)
    }
    case 'SET_LOADING_WAYPOINTS':
      return updateReducerLiterals(state, { loadingWaypoints: true })
    case 'SET_MODE':
      return updateReducerLiterals(state, {
        mode: action.mode,
        hasEnteredDgsMode: action.mode === 'dgs' || state.hasEnteredDgsMode,
      })
    case 'SET_LOADING_DONE':
      return updateReducerLiterals(state, { loading: false, loadingWaypoints: false })
    case 'ERROR':
      return { ...state, error: action.error }
    case 'SET_IMAGE_DATA': {
      const newState = { ...state, loading: false }

      if (action.imageData) {
        const collectionContext = findCollectionResource(action.imageData?.collections?.[0])?.id
        const isWaypoint = imageHasWaypoints(action.imageData)
        const noImageIndexes = !action.imageData.records?.length

        newState.imageData = action.imageData
        newState.dgsNum = action.imageData?.dgsNum
        newState.noImageIndexes = noImageIndexes
        newState.collectionContext = collectionContext
        newState.isWaypoint = isWaypoint
      }

      return newState
    }
    case 'SET_SAVE_BOTTOM_SHEET_HEIGHT':
      return updateReducerLiterals(state, { saveBottomSheetHeight: action.value })
    case 'SET_WAYPOINT_DATA':
    case 'SET_FILM_DATA':
    case 'SET_DGS_FILM_DATA': {
      const camelize = (str) => str.toLowerCase().replace(/_[a-z]/g, (ch) => ch.slice(1).toUpperCase())
      const newState = { ...state, loadingWaypoints: false }
      // e.g 'SET_MY_THING' => 'myThing'
      const dataKey = camelize(action.type.replace(/^set_/i, ''))
      const hasImagePermissions = state.error?.response?.status !== 403

      if (action[dataKey]) newState[dataKey] = action[dataKey]

      if (action.type === 'SET_DGS_FILM_DATA') {
        newState.dgsNum = action.dgsNum
        newState.loading = false
      }

      if (hasImagePermissions) {
        const viewerImages = getViewerImages(action?.filmData || action?.dgsFilmData || action?.waypointData)
        // Format image objects for grid view mode in the ComboViewer
        const gridViewerImages = viewerImages?.map((img) => ({ ...img, src: img.p200 }))

        newState.images = viewerImages
        newState.p200Images = gridViewerImages
      }

      return newState
    }
    case 'SET_VIEWED_IMAGES': {
      return { ...state, viewedImages: new Set([...state.viewedImages, action.viewedImage]) }
    }
    case 'SPIN_OUT':
      return updateReducerLiterals(state, { spinOut: true })
    case 'SET_LAST_IMAGE_INFO_TIMESTAMP':
      return updateReducerLiterals(state, { lastImageInfoTimestamp: action.timestamp })
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

/**
 * Check if the image from the initial image-data API call has waypoints (this determines whether we need to call
 * filmdatainfo with type: waypoint-data or film-data).
 * @param {Object} imageData filmdata returned from the search-filmdata API
 * @returns {boolean}
 */
export function imageHasWaypoints(imageData) {
  const hasCollectionSourceDescription = imageData?.meta?.sourceDescriptions?.some(
    (sd) => sd?.resourceType === 'http://gedcomx.org/Collection'
  )
  const hasWaypointCollectionLink = imageData?.collections?.some((collection) => collection?.links?.waypoints)
  const hasSourceDescriptionWaypoint = imageData?.meta?.sourceDescriptions?.some(
    // require componentOf description AND waypoints identifier URL (URL must have something like "/waypoints/" in it)
    (sd) => sd?.componentOf?.description && /\bwaypoints\b/i.test(sd?.identifiers?.['http://gedcomx.org/Primary']?.[0])
  )
  const hasWaypointUrl = hasCollectionSourceDescription && (hasWaypointCollectionLink || hasSourceDescriptionWaypoint)
  const waypointUrl = hasWaypointUrl ? findCollectionResource(imageData?.meta)?.about : null
  return waypointUrl && waypointUrl.includes('/waypoints/')
}

/**
 * Searches for a sourceDescription with the resource type of "Collection"
 * @param {Object} record - record that contains sourceDescriptions
 * @returns A sourceDescription with the resource type of "Collection"
 */
export function findCollectionResource(record) {
  return record?.sourceDescriptions?.find((sd) => sd?.resourceType === 'http://gedcomx.org/Collection')
}

/**
 * Updates the reducer state without triggering rerenders if the values didn't change
 * Should only be used for literal values
 * @param {Object} state the state of the reducer
 * @param {Object} literals the values to update
 * @returns State object
 */
function updateReducerLiterals(state, literals) {
  const shouldUpdate = Object.entries(literals).some(([key, value]) => value !== state[key])
  if (!shouldUpdate) return state

  return {
    ...state,
    ...literals,
  }
}

/**
 * Formats images from the waypoint or filmdata API calls for use by the ComboViewer
 * @param {Ojbect} data the data from the waypoint or filmdata API call
 * @returns {Array} Array of image objects formatted for use by the ComboViewer
 */
function getViewerImages(data) {
  return data?.images?.map((image, index) => {
    // e.g. https://www.familysearch.org/ark:/61903/3:1:9Q97-Y39T-CP1?cc=1784216&wc=3N7Y-W36%3A1584309303%2C1584311302%2C1584311303 -> ['3:1:9Q97-Y39T-CP1']
    const arkMatchArray = image.match(/3:[1-2]:[\w-]+(?=[/?]|\/)/)

    // e.g. 3:1:9Q97-Y39T-CP1
    const ark = arkMatchArray?.[0]

    // Every request to filmdatainfo with type = waypoint-data || film-data will have template strings for the image and thumbnail urls that get passed into the ComboViewer
    // dz stands for deep zoom
    const { dzTemplate } = data?.templates

    // The two possible images we want to display in the ComboViewer, and the printURL is the image that gets printed
    const imageFormats = {
      mainImage: 'image.xml',
      thumbnail: 'thumb_p200.jpg',
      printURL: 'dist.jpg',
    }

    /*
     * If the target environment is not production, we need to remove the base URL (SG URL) from the image and thumbnail URLs
     * e.g. https://sg30p0.familysearch.org/service/records/storage/deepzoomcloud/dz/v1/3:1:3QS7-89MR-YZ4/image.xml
     * becomes https://beta.familysearch.org/service/records/storage/deepzoomcloud/dz/v1/3:1:3QS7-89MR-YZ4/image.xml
     */
    function nonProdReplacer(match) {
      if (sgBaseUrl !== 'https://sg30p0.familysearch.org') return match.replace('https://sg30p0.familysearch.org', '')
      return match
    }

    // e.g. https://sg30p0.familysearch.org/service/records/storage/deepzoomcloud/dz/v1/3:1:3QS7-89MR-YZ4/image.xml
    const src = dzTemplate
      .replace('{id}', ark)
      .replace('{image}', imageFormats.mainImage)
      .replace('https://sg30p0.familysearch.org', nonProdReplacer)

    // e.g. https://sg30p0.familysearch.org/service/records/storage/deepzoomcloud/dz/v1/3:1:3QS7-89MR-YZ4/thumb_p200.jpg
    const p200 = dzTemplate
      .replace('{id}', ark)
      .replace('{image}', imageFormats.thumbnail)
      .replace('https://sg30p0.familysearch.org', nonProdReplacer)

    // Format image objests for the ComboViewer
    return {
      src,
      alt: ark,
      id: ark,
      p200,
      ark,
      index,
    }
  })
}
