import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import ErrorBoundary from '@fs/zion-error-boundary'
import { Trans, useTranslation } from 'react-i18next'
import { Separator } from '@fs/zion-ui'
import UnknownError from '../error-components/UnknownError'

/**
 * Error catcher and error message display component for Film Viewer
 * @param {String} ark - image ark; '3:1:Q295-5MC2'
 * @param {[React.ReactNode]|React.ReactNode} children - React children
 * @returns {JSX.Element}
 * @constructor
 */
export default function FilmViewerErrorBoundary({ ark, children }) {
  // Stores error boundary state... for caught JS errors that are thrown inside FilmViewer.
  // Some are known errors, but others will be unexpected.
  const [filmViewerError, setFilmViewerError] = useState()
  /**
   * If a JS error happens anywhere inside film viewer, this fallback component will render instead of FilmViewer.
   */
  const Fallback = useCallback(() => {
    // Bad ark error display section
    if (filmViewerError?.type === 'badArkFormat') {
      return <BadArkErrorComponent ark={ark} />
    }
    if (['badWaypointId', 'badCollectionId'].includes(filmViewerError?.type)) {
      return <MalformedUrl />
    }
    if (filmViewerError?.type === 'filmNotFound') {
      return <FilmNotFound filmNumber={filmViewerError?.details?.filmNumber} />
    }
    if (filmViewerError?.type === 'filmHasNoImages') {
      return <FilmHasNoImages filmNumber={filmViewerError?.details?.filmNumber} />
    }

    return <UnknownError pageType="filmViewer" />
  }, [ark, filmViewerError])

  function errorHandler(error) {
    setFilmViewerError(error)
  }
  return (
    <ErrorBoundary errorHandler={errorHandler} FallbackComponent={Fallback}>
      {children}
    </ErrorBoundary>
  )
}

FilmViewerErrorBoundary.propTypes = {
  /* The image ark URL for filmViewer */
  ark: PropTypes.string,

  /* once data has loaded, these components should render */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

/**
 * Create a translated error message notifying the user of a bad ark
 * @param {string} ark - image ark url reference
 * @returns {JSX.Element|string}
 */
function BadArkErrorComponent({ ark }) {
  return (
    <DefaultFilmViewerError
      message={
        <Trans
          i18nKey="film-viewer.error.arkParseError"
          defaults="The ark {ark} is invalid."
          values={{
            ark,
          }}
        />
      }
    />
  )
}

/**
 * Create a translated error message notifying the user of a malformed url (e.g. bad waypoint id)
 * @returns {JSX.Element}
 */
function MalformedUrl() {
  const { t } = useTranslation()
  return <DefaultFilmViewerError message={t('film-viewer.error.malformed-url.error', 'The URL may be malformed.')} />
}

/**
 * Show an error when the film is not found (404 from filmdatainfo for DGS data, e.g. 999999999).
 * @param {string} filmNumber - film number
 * @returns {JSX.Element}
 */
function FilmNotFound({ filmNumber }) {
  return (
    <DefaultFilmViewerError
      message={
        <Trans
          i18nKey="film-viewer.error.filmNotFound"
          defaults="Film number {filmNumber} not found."
          values={{
            filmNumber,
          }}
        />
      }
    />
  )
}

/**
 * Show an error when the film has no images in the image array (from filmDataInfo).
 * @param {string} filmNumber - film number
 * @returns {JSX.Element}
 */
function FilmHasNoImages({ filmNumber }) {
  return (
    <DefaultFilmViewerError
      message={
        <Trans
          i18nKey="film-viewer.error.filmHasNoImages"
          defaults="Film number {filmNumber} has no images."
          values={{
            filmNumber,
          }}
        />
      }
    />
  )
}

/**
 * Generic error page for Film Viewer
 * @param {string} ark - record ark url reference
 * @param {string} message - custom error message
 * @returns {JSX.Element}
 * @constructor
 */
function DefaultFilmViewerError({ message }) {
  return (
    <UnknownError
      message={
        <>
          {message}
          <br />
          <Separator />
        </>
      }
    />
  )
}

/**
 * A fully-fledged Error object for throwing film viewer problems
 * @param {String} type - the error type
 * @constructor
 */
export function FVError({ type, details = {} }) {
  const error = new Error(`${type} found on FV page`)
  error.name = 'FVError'
  error.type = type
  error.details = details
  return error
}
