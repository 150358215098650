import React, { useState, createContext, useEffect, useCallback } from 'react'
import { setPreference } from '@fs/zion-preferences'

const DarkModeContext = createContext({})

export default function DarkModeProvider({ children }) {
  const DARK_MODE_PREFERENCE_KEY = 'search.darkMode'
  const [isDarkModeOn, setIsDarkModeOn] = useState()
  const setIsDarkMode = useCallback(async (isOn) => {
    setIsDarkModeOn(isOn)
    await setPreference(DARK_MODE_PREFERENCE_KEY, isOn)
  }, [])

  const [initialDarkModePreference, setInitialDarkModePreference] = useState()

  // usePreference never updates the value of darkModeOn @see https://beta.familysearch.org/frontier/zion/?path=/docs/utilities-user-preferences--docs#usepreference
  useEffect(() => {
    if (initialDarkModePreference) setIsDarkModeOn(initialDarkModePreference)
    else setIsDarkModeOn(false)
  }, [initialDarkModePreference])
  const [darkModePermitted, setDarkModePermitted] = useState(false)

  const theme = isDarkModeOn && darkModePermitted ? 'night' : 'day'

  return (
    <DarkModeContext.Provider
      value={{
        isDarkModeOn,
        loading: typeof isDarkModeOn === 'undefined',
        setIsDarkModeOn: setIsDarkMode,
        theme,
        setDarkModePermitted,
        setInitialDarkModePreference,
        DARK_MODE_PREFERENCE_KEY,
      }}
    >
      {children}
    </DarkModeContext.Provider>
  )
}

export { DarkModeContext }
