import React, { Suspense, lazy, useEffect } from 'react'
import {
  AnonSessionRoute,
  AuthRoute,
  NotFound,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from '@fs/zion-router'
import { targetEnv } from '@fs/zion-config'
import ErrorBoundary from '@fs/zion-error-boundary'
import { RecordAuthRoute } from '@fs/zion-gedcomx'
import { LoadingSkeleton as RecordLoadingSkeleton } from '@fs/zion-record-details'
import Cookies from 'js-cookie'
import { useContainerWidth } from '@fs/zion-ui'
import { useUser } from '@fs/zion-user'
import useMeasure from 'react-use-measure'
import { useFeatureFlag } from '@fs/zion-flags'
import UnknownError from './components/error-components/UnknownError'
import NavigationTabs from './components/hr/NavigationTabs'
import lightningjs from './lightningjs'
import CenteredSpinner from './components/shared/CenteredSpinner'
import useFilmViewerRedirects from './components/film-viewer/useFilmViewerRedirects'
import useCatalogRedirects from './components/catalog/useCatalogRedirects'
import useHrRedirects from './components/hr/useHrRedirects'

const Suspend = ({ children }) => <Suspense fallback={<CenteredSpinner />}>{children}</Suspense>

/**
 * This will retry failed chunks up to 5 times
 * Hopefully it will decrease Sentry errors
 * @param {Function} lazyComponent - lazy component import function
 * @param {Number} attemptsLeft - number of attempts to make (defaults to 5)
 * @returns {Promise<any>} Promise that rejects if all additional attempts fail.
 */
function componentLoader(lazyComponent, attemptsLeft = 5) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 100 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error)
            return
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject)
        }, 100)
      })
  })
}

// Dynamically load components to reduce bundle size
// https://reactjs.org/docs/react-api.html#reactlazy
const CatalogDetails = lazy(() =>
  componentLoader(() => import('./components/catalog/catalog-details/CatalogDetailsPage'))
)
const CatalogResultsPage = lazy(() => componentLoader(() => import('./components/catalog/CatalogResultsPage')))
const CatalogSearchPage = lazy(() => componentLoader(() => import('./components/catalog/CatalogSearchPage')))
const CatalogPrintListPage = lazy(() => componentLoader(() => import('./components/catalog/CatalogPrintListPage')))
const JiapuAttach = lazy(() => componentLoader(() => import('./components/prototypes/jiapu-attach/JiapuAttach')))

const CollectionDetails = lazy(() => componentLoader(() => import('./components/hr/CollectionDetailsPage')))
const CollectionListPage = lazy(() => componentLoader(() => import('./components/hr/CollectionListPage')))
const FilmDebugPage = lazy(() => componentLoader(() => import('./components/prototypes/film-debug/FilmDebugPage')))
const GenCollectionDetailsPage = lazy(() => componentLoader(() => import('./components/gen/GenCollectionDetailsPage')))
const GenSearchPage = lazy(() => componentLoader(() => import('./components/gen/GenSearchPage')))
const GenResultsPage = lazy(() => componentLoader(() => import('./components/gen/GenResultsPage')))
const GenDetailsPage = lazy(() => componentLoader(() => import('./components/gen/GenDetailsPage')))
const GenSubmissionsPage = lazy(() => componentLoader(() => import('./components/gen/GenSubmissionsPage')))
const GenSubmissionDetailsPage = lazy(() => componentLoader(() => import('./components/gen/GenSubmissionDetailsPage')))
const HRComparePage = lazy(() => componentLoader(() => import('./components/prototypes/hr/HRComparePage')))
const LocationListPage = lazy(() => componentLoader(() => import('./components/location/LocationListPage')))
const LocationPage = lazy(() => componentLoader(() => import('./components/location/LocationPage')))
const RecordDebugPage = lazy(() =>
  componentLoader(() => import('./components/prototypes/record-debug/RecordDebugPage'))
)
const RecordDetailsPage = lazy(() => componentLoader(() => import('./components/hr/RecordDetailsPage')))
const RecordRedirectToPersona = lazy(() => componentLoader(() => import('./components/hr/RecordRedirectToPersona')))
const SearchHomePage = lazy(() => componentLoader(() => import('./components/hr/SearchHomePage')))
const SearchResultsPage = lazy(() => componentLoader(() => import('./components/hr/SearchResultsPage')))
const SearchResultsLoadingSkeleton = lazy(() =>
  componentLoader(() => import('@fs/zion-record-search').then((module) => ({ default: module.LoadingSkeletons })))
)
const SourceLinkerPage = lazy(() => componentLoader(() => import('./components/sourcelinker/SourceLinkerPage')))

const TreeSearchPage = lazy(() => componentLoader(() => import('./components/tree/TreeSearchPage')))
const TreeResultsPage = lazy(() => componentLoader(() => import('./components/tree/TreeResultsPage')))
const TreeCETDetailsPage = lazy(() => componentLoader(() => import('./components/tree/TreeCETDetailsPage')))
const TreeBrowsePage = lazy(() => componentLoader(() => import('./components/tree/TreeBrowsePage')))
const TreeToTreeLinkerPage = lazy(() =>
  componentLoader(() => import('./components/sourcelinker/tree-to-tree/TreeToTreePage'))
)

const FilmViewerPage = lazy(() => componentLoader(() => import('./components/film-viewer/FilmViewerPage')))

const FullTextSearchPage = lazy(() => componentLoader(() => import('./components/full-text-search/FullTextSearchPage')))
const FullTextResultsPage = lazy(() =>
  componentLoader(() => import('./components/full-text-search/FullTextResultsPage'))
)
const FullTextCollectionDetailsPage = lazy(() =>
  componentLoader(() => import('./components/full-text-search/FullTextCollectionDetailsPage'))
)
const FullTextCollectionsListPage = lazy(() =>
  componentLoader(() => import('./components/full-text-search/FullTextCollectionsListPage'))
)

const EMBEDDED_MOBILE_COOKIE_NAME = 'embeddedMobile'

function App({ setUseFatFooter }) {
  // Hide NavigationTabs if page is embedded in mobile app WebView
  const embeddedMobile = !!Cookies.get(EMBEDDED_MOBILE_COOKIE_NAME)
  const atWidth = useContainerWidth()
  const isMobile = atWidth({ default: true, xl: false })
  const hideSubNav = isMobile || embeddedMobile

  const { signedIn } = useUser()
  const { pathname, search } = useLocation()
  const homePage = '/search/'

  useEffect(() => {
    // eslint-disable-next-line babel/no-unused-expressions
    window.lightningjs || lightningjs

    if (pathname.match(/\/location\/+\/+/i)) {
      window.usabilla_live = window.lightningjs.require('usabilla_live', '//w.usabilla.com/08c3d906fb80.js')
      window.usabilla_live('virtualPageView')
    }

    if (pathname === homePage && !signedIn) {
      setUseFatFooter(true)
    } else {
      setUseFatFooter(false)
    }
  }, [homePage, pathname, setUseFatFooter, signedIn])

  const query = useHistory()?.location?.search || ''

  const {
    filmViewerHighlightRedirectUrl,
    filmViewerRedirectArkUri,
    filmViewerWaypointRedirectUri,
    filmViewerWaypointArkRedirectUri,
    filmViewerBrokenWaypointLink,
    filmViewerBadOpenedWaypoints,
    filmViewerExtraneousWaypointContext,
    filmViewerRedirectOpenWaypointChooser,
  } = useFilmViewerRedirects()

  const { catalogShowRedirectUrl, catalogDeprecatedPathUrl } = useCatalogRedirects()
  const hrRedirect = useHrRedirects()

  const [navRef, navBounds] = useMeasure()
  const subnavHeight = Math.ceil(navBounds?.height || 0)
  const searchRouteLinkerFF = useFeatureFlag('search_treeLinker')?.isOn

  const redirectUrlForLangSlashArk = getRedirectUrlForLangSlashArk({ pathname, search })
  if (redirectUrlForLangSlashArk) {
    // NOTE: return <Redirect to={redirectUrlForLangSlashArk} /> does not work here because pathname does not include the language code at the beginning of the path
    window.history.replaceState({}, '', redirectUrlForLangSlashArk)
  }

  // "Switch" only switches on direct children, so routes and redirects have to be direct children.
  return (
    <div style={{ '--subnav-height': `${subnavHeight}px`, height: '100%' }}>
      <ErrorBoundary FallbackComponent={UnknownError}>
        <Suspense fallback={<CenteredSpinner />}>
          {!hideSubNav && <NavigationTabs navRef={navRef} />}

          {
            <Switch>
              {/* Film Viewer Routes */}
              {/* redirect to waypoint chooser using uri hash/path (see getFilmViewerWaypointRedirectUri function) */}
              {filmViewerWaypointRedirectUri && <Redirect from="/search/image" to={filmViewerWaypointRedirectUri} />}
              {/* redirect to image using uri hash/path (see getFilmViewerWaypointArkRedirectUri function) */}
              {filmViewerWaypointArkRedirectUri && (
                <Redirect
                  from="/ark\:/61903/3\:(1|2)\::arkPath"
                  to={filmViewerWaypointArkRedirectUri.replace(/:/g, '\\:')}
                />
              )}
              {/* broken link that happened from a bug in old film viewer (see https://fhjira.churchofjesuschrist.org/browse/FSS-10252) */}
              {filmViewerBrokenWaypointLink && (
                <Redirect
                  from="/search/image/index"
                  to={{
                    pathname: '/search/image/index',
                  }}
                />
              )}
              {/* bad opened waypoints link that happened from a bug in old film viewer (see https://fhjira.churchofjesuschrist.org/browse/FSS-10225) */}
              {filmViewerBadOpenedWaypoints && (
                <Redirect from={pathname} to={{ pathname, search: filmViewerBadOpenedWaypoints?.search }} />
              )}
              {/* extraneous waypoint context param that happened from a bug in old film viewer where owc and wc were present */}
              {filmViewerExtraneousWaypointContext && (
                <Redirect
                  from={pathname}
                  to={{
                    pathname: filmViewerExtraneousWaypointContext?.pathname,
                    search: filmViewerExtraneousWaypointContext?.search,
                  }}
                />
              )}
              {/* redirect to give owc param full path (see getFilmViewerRedirectOpenWaypointChooser function) */}
              {filmViewerRedirectOpenWaypointChooser && (
                <Redirect
                  from={pathname}
                  to={{
                    pathname: filmViewerRedirectOpenWaypointChooser?.pathname,
                    search: filmViewerRedirectOpenWaypointChooser?.search,
                  }}
                />
              )}
              {/* 'waypoint-chooser' mode - waypoint viewer, /search/image/index */}
              <RecordAuthRoute
                path="/search/image/index"
                component={FilmViewerPage}
                LoadingSkeleton={<CenteredSpinner />}
              />
              {/* 'dgs' mode - film viewer, /search/film/:dgsNum */}
              <RecordAuthRoute
                path="/search/film/:dgsNum"
                component={FilmViewerPage}
                LoadingSkeleton={<CenteredSpinner />}
              />
              {/* 'ark' mode - ark viewer, /ark:/61903/:arkPath */}
              <RecordAuthRoute
                path="/ark\:/61903/3\:(1|2)\::arkPath"
                component={FilmViewerPage}
                LoadingSkeleton={<CenteredSpinner />}
              />
              {/* redirect to an image with a `bounds` (highlights) query param */}
              {filmViewerHighlightRedirectUrl && (
                <Redirect
                  from="/search/image/highlight"
                  exact
                  to={`${filmViewerHighlightRedirectUrl.replace(/:/g, '\\:')}`}
                />
              )}
              {/* redirect to the `uri` param */}
              {filmViewerRedirectArkUri && (
                // the `replace` escapes `:` with `\:` so that React Router does not treat the ark as a match param
                <>
                  <Redirect from="/search/image/uri" exact to={filmViewerRedirectArkUri} />
                  <Redirect from="/search/image/viewer" exact to={filmViewerRedirectArkUri} />
                </>
              )}
              {/* empty landing - not a useful page - old film viewer was receiving traffic to this so we want it too */}
              <AnonSessionRoute
                path="/search/image/viewer"
                exact
                component={FilmViewerPage}
                LoadingSkeleton={<CenteredSpinner />}
              />
              {/* Full-Text Search Routes */}
              <AuthRoute
                path="/search/full-text/collection/list"
                component={FullTextCollectionsListPage}
                requirePersonalData
              />
              <AuthRoute
                path="/search/full-text/collection/:collectionId"
                component={FullTextCollectionDetailsPage}
                requirePersonalData
              />
              <AuthRoute path="/search/full-text/results" component={FullTextResultsPage} requirePersonalData />
              <AuthRoute path="/search/full-text" component={FullTextSearchPage} requirePersonalData />
              {/* Redirect for localhost convenience only */}
              {targetEnv === 'local' && <Redirect from="/" exact to="/search/" />}
              {/* Search Home */}
              <AnonSessionRoute path="/search/" exact LoadingComponent={CenteredSpinner}>
                <Suspend>
                  <SearchHomePage />
                </Suspend>
              </AnonSessionRoute>
              {/* Record Details */}
              <RecordAuthRoute
                path="/ark\:/61903/1\:1\::arkPath"
                component={RecordDetailsPage}
                LoadingSkeleton={<RecordLoadingSkeleton />}
              />
              {/* Ark 1:2 paths are long-lived URLs for records, but full records don't have dedicated pages. */}
              {/* Instead, we redirect users to the first 1:1 persona in the record. */}
              {/* This route finds the first persona and does a redirect. */}
              {/* If the user doesn't have permission to see the record, we redirect to a login instead. */}
              <AnonSessionRoute path="/ark\:/61903/1\:2\::arkPath" component={RecordRedirectToPersona} />

              {/* These location redirects are required BEFORE the HR collections paths */}
              <Redirect from="/search/collection/location/:region" exact to={`/search/location/:region?${query}`} />
              <Redirect
                from="/search/collection/location/:region/:subRegion"
                exact
                to={`/search/location/:region/:subRegion?${query}`}
              />
              {/* HR Collections */}
              <AnonSessionRoute path="/search/collection/list" component={CollectionListPage} />
              <AnonSessionRoute path="/1940census" component={CollectionDetails} />
              <AnonSessionRoute path="/search/collection/:collectionId" component={CollectionDetails} />
              {/* Locations */}
              <AnonSessionRoute path="/search/location/list" exact component={LocationListPage} />
              <AnonSessionRoute path="/search/location/:region" exact component={LocationPage} />
              <AnonSessionRoute path="/search/location/:region/:subRegion" exact component={LocationPage} />
              {/* HR Search Results */}
              {hrRedirect && <Redirect exact from="/search/record/results" to={hrRedirect} />}
              <RecordAuthRoute
                path="/search/record/results"
                component={SearchResultsPage}
                LoadingSkeleton={<SearchResultsLoadingSkeleton />}
              />
              {/* Tree Search & Results */}
              <AuthRoute path="/search/tree/results" component={TreeResultsPage} />
              {/* Tree Browse List Page */}
              <AuthRoute path="/search/tree/list" component={TreeBrowsePage} />

              {/* Tree Linker */}
              {searchRouteLinkerFF && (
                // query parameter
                <AuthRoute path="/search/tree/linker" component={TreeToTreeLinkerPage} />
              )}

              {/* /search/tree, /search/tree/id, and /search/tree/name */}
              <AnonSessionRoute path="/search/tree" exact component={TreeSearchPage} />

              <AnonSessionRoute path="/search/tree/:searchType" exact component={TreeSearchPage} />

              {/* /search/<collectionId>/<treeId>, /search/<collectionId>/<treeId>/id, and /search/tree/<collectionId>/<treeId>/name */}
              <AnonSessionRoute path="/search/tree/:collectionId/:treeId" exact component={TreeCETDetailsPage} />
              <AnonSessionRoute path="/search/tree/:collectionId/:treeId/:searchType" component={TreeCETDetailsPage} />

              {/* Genealogies */}
              {<AnonSessionRoute path="/search/genealogies" exact component={GenSearchPage} requirePersonalData />}
              <AnonSessionRoute path="/search/genealogies/results" component={GenResultsPage} requirePersonalData />
              <AnonSessionRoute
                path="/search/genealogies/collection/:collectionId"
                component={GenCollectionDetailsPage}
                requirePersonalData
              />
              <AnonSessionRoute
                path="/search/genealogies/submissions"
                component={GenSubmissionsPage}
                requirePersonalData
              />
              <AnonSessionRoute
                path="/search/genealogies/submission/:collectionId/:submissionId"
                component={GenSubmissionDetailsPage}
                requirePersonalData
              />
              <AnonSessionRoute path="/search/cgt" exact component={GenSubmissionsPage} requirePersonalData />
              {/* Source Linker */}
              {<AuthRoute path="/search/linker" component={SourceLinkerPage} />}
              {/* Genealogies Details */}
              <AnonSessionRoute path="/ark\:/61903/:arkPath" component={GenDetailsPage} requirePersonalData />
              {/* Redirects for genealogies pages */}
              <Redirect exact from="/search/family-trees" to={`/search/genealogies${query}`} />
              <Redirect exact from="/search/family-trees/results" to={`/search/genealogies/results${query}`} />
              {/* Old routes for genealogies pages */}
              {<Route path="/search/family-trees" exact component={GenSearchPage} requirePersonalData />}
              <AnonSessionRoute path="/search/family-trees/results" component={GenResultsPage} requirePersonalData />
              {/* Catalog Search & Results */}
              <AnonSessionRoute
                path="/search/catalog/results"
                component={CatalogResultsPage}
                requirePersonalData={!signedIn}
              />
              <AnonSessionRoute
                path="/search/catalog"
                exact
                component={CatalogSearchPage}
                requirePersonalData={!signedIn}
              />
              {/* Catalog Print List */}
              <AnonSessionRoute
                path="/search/catalog/print-list"
                component={CatalogPrintListPage}
                requirePersonalData={!signedIn}
              />
              {/* Catalog Details */}
              {catalogShowRedirectUrl && <Redirect exact from="/search/catalog/show" to={catalogShowRedirectUrl} />}
              {/* Deprecated catalog paths */}
              <Redirect exact from="/search/catalog-search" to={catalogDeprecatedPathUrl} />
              <Redirect from="/catalog-search" to={catalogDeprecatedPathUrl} />
              <Redirect from="/catalog/search" to={catalogDeprecatedPathUrl} />
              <Redirect from="/eng/library/fhlc" to={catalogDeprecatedPathUrl} />
              <Redirect from="/eng/library/fhlcatalog" to={catalogDeprecatedPathUrl} />
              <Redirect from="/eng/library/fhlc/frameset_fhlc.asp" to={catalogDeprecatedPathUrl} />
              <Redirect
                from="/search/collection/results"
                to={{
                  pathname: pathname.replace('/search/collection/results', '/search/record/results'),
                  search,
                }}
              />
              {/* Handle old version of search-bifrost routes (this only handles /frontier/search-bifrost/<path-and-query> -> /search/<path-and-query> */}
              <Redirect
                from="/frontier/search-bifrost"
                to={{
                  pathname: pathname.replace('/frontier/search-bifrost', '/search'),
                  search,
                }}
              />
              <Redirect
                from="/search/search"
                to={{
                  pathname: pathname.replace('/search/search', '/search'),
                  search,
                }}
              />
              <Redirect
                from="/search/collection/results"
                to={{
                  pathname: pathname.replace('/search/collection/results', '/search/record/results'),
                  search,
                }}
              />
              <AnonSessionRoute
                path="/search/catalog(/oclc)?/:catId"
                component={CatalogDetails}
                requirePersonalData={!signedIn}
              />
              {/* hidden routes; users shouldn't visit these */}
              <AnonSessionRoute path="/search/filmdebug" component={FilmDebugPage} />
              <AuthRoute path="/search/hrcompare" component={HRComparePage} />
              <AnonSessionRoute path="/search/recorddebug" component={RecordDebugPage} />
              <Route path="/search/blank" component={() => 'This page is intentionally left blank'} />
              <AuthRoute path="/search/jiapu-attach" component={JiapuAttach} />
              {/* no route found */}
              <Route component={NotFound} />
            </Switch>
          }
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

function getRedirectUrlForLangSlashArk({ pathname, search }) {
  const langCodePathWithArkRegex = /^\/(?<localeCode>[a-z]{2,3}(-[a-zA-Z0-9-]*)?\/)ark:\/61903\// // Copied from DTM haproxy config MATCH_root_lang_path_with_slash acl
  // using window location instead of useLocation since /<langCodePath> is missing from useLocation pathname
  const matches = window?.location?.pathname?.match(langCodePathWithArkRegex)
  const beginLocale = matches?.groups?.localeCode || ''

  if (beginLocale) {
    // e.g. /en/ark:/61903/3:1:33S7-9YB9-9JZF?cc=1727033 -> /ark:/61903/3:1:3Q9M-CS8L-9J?i=1&cc=1968530
    return `${pathname.replace(beginLocale, '')}${search}`
  }

  return null
}

export default App
