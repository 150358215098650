import { sgBaseUrl } from '@fs/zion-config'
import { useLocation, useQueryParams } from '@fs/zion-router'
import { extractArk } from './useFilmData'

// normally baseUrl would be fine, but we want the corresponding deployed base URL
const envUrl =
  {
    'https://sg30p0.familysearch.org': 'https://www.familysearch.org',
    'https://sg31b0.familysearch.org': 'https://beta.familysearch.org',
    'https://sg32i0.familysearch.org': 'https://integration.familysearch.org',
  }?.[sgBaseUrl] || 'https://www.familysearch.org'

/**
 * Custom hook to get the redirect URLs for the film viewer.
 * These are used to fix broken links that have been circulated out on the web for years. The links were created by
 * bugs (in both the React and old film viewers) or inconsistencies in the old film viewer. See Film Viewer Readme
 * for example links of each case.
 * @returns {Object} - The redirect URLs for the film viewer.
 */
export default function useFilmViewerRedirects() {
  const { pathname, hash } = useLocation()
  const search = useLocation()?.search || ''
  const {
    query: { url: urlParam, bounds: boundsParam, uri: uriParam, owc: owcParam, wc: wcParam },
  } = useQueryParams()

  // return early if not a film viewer URL
  const isFilmViewerUrl =
    pathname.startsWith('/ark:/61903/3:') ||
    pathname.startsWith('/ark:/61903/3:2:') ||
    pathname.startsWith('/search/image/') ||
    pathname.startsWith('/search/film/')
  if (!isFilmViewerUrl) {
    return {
      filmViewerHighlightRedirectUrl: '',
      filmViewerRedirectArkUri: '',
      filmViewerWaypointRedirectUri: '',
      filmViewerWaypointArkRedirectUri: '',
      filmViewerBrokenWaypointLink: false,
      filmViewerBadOpenedWaypoints: '',
      filmViewerExtraneousWaypointContext: '',
    }
  }

  const filmViewerHighlightRedirectUrl = getFilmViewerHighlightRedirectUrl(urlParam, boundsParam, pathname)
  const filmViewerRedirectArkUri = uriParam ? getFilmViewerRedirectArkUri(uriParam) : ''
  const filmViewerWaypointRedirectUri = getFilmViewerWaypointRedirectUri(pathname, hash)
  const filmViewerWaypointArkRedirectUri = getFilmViewerWaypointArkRedirectUri(pathname, hash)
  const filmViewerBrokenWaypointLink = pathname === '/search/image/index' && ['null', 'waypoints'].includes(owcParam)
  const filmViewerBadOpenedWaypoints = !filmViewerBrokenWaypointLink
    ? getFilmViewerBadOpenedWaypointsPathname(pathname, owcParam, search)
    : ''
  const filmViewerExtraneousWaypointContext = getFilmViewerExtraneousWaypointContext(
    pathname,
    owcParam,
    wcParam,
    search
  )
  const filmViewerRedirectOpenWaypointChooser = getRedirectOpenWaypointChooser(pathname, owcParam, search)

  return {
    filmViewerHighlightRedirectUrl,
    filmViewerRedirectArkUri,
    filmViewerWaypointRedirectUri,
    filmViewerWaypointArkRedirectUri,
    filmViewerBrokenWaypointLink,
    filmViewerBadOpenedWaypoints,
    filmViewerExtraneousWaypointContext,
    filmViewerRedirectOpenWaypointChooser,
  }
}

/**
 * Get the redirect URL for the film viewer highlight when a bounds (highlights) url param is used.
 * This is used to redirect the user to the film viewer with the highlight bounds.
 * @param {string} url - The URL query param (e.g. "https://www.familysearch.org/dz/v1/3:1:3QS7-L9WZ-399R/image.xml").
 * @param {string} bounds - The bounds query param (e.g. "[1011,874:1216,918][510,1397:725,1470][1219,874:1431,918]").
 * @param {string} pathname - The pathname of the current URL.
 * @returns {string} - The redirect URL for the film viewer highlight (e.g. `/ark:/61903/3:1:S3HY-D1C8-K1?bounds=[1011,874:1216,918][510,1397:725,1470][1219,874:1431,918]&boundsArk=3:1:3QS7-L9WZ-399R`).
 */
function getFilmViewerHighlightRedirectUrl(url, bounds, pathname) {
  if (url && pathname === '/search/image/highlight') {
    const ark = extractArk(url)
    return `/ark:/61903/${ark}?bounds=${bounds}&boundsArk=${ark}`
  }
  return ''
}

/**
 * Get the redirect URI for the film viewer.
 * @param {string} uri - The URI query param (e.g. `https://www.familysearch.org/ark:/61903/3:1:S3HY-D1C8-K1?i=466&i=466&cc=1951739`).
 * @returns {string} - The redirect URI for the film viewer (e.g. `/ark:/61903/3:1:S3HY-D1C8-K1?i=466`).
 */
function getFilmViewerRedirectArkUri(uri) {
  if (uri && extractArk(uri)) {
    try {
      const pathname = `/ark:/61903/${extractArk(uri)}`
      const { search } = new URL(uri)
      return `${pathname}${search}`
    } catch (error) {
      return ''
    }
  }
  return ''
}

/**
 * Get the redirect URI for the film viewer.
 *   (e.g. of the URL: `/search/image/index#uri=https://www.familysearch.org/recapi/sord/collection/2101574/waypoints`,
 *   `/search/image/uri=https://www.familysearch.org/recapi/sord/collection/2101574/waypoints`, or
 *   `/search/image/index/uri=https://www.familysearch.org/recapi/sord/collection/2101574/waypoints`).
 * @param {string} pathname - The pathname of the current URL.
 * @param {string} hash - The hash of the current URL.
 * @returns {string} - The redirect URI for the film Viewer
 *   (e.g. `/search/image/index?owc=https://www.familysearch.org/recapi/sord/collection/2101574/waypoints`).
 */
function getFilmViewerWaypointRedirectUri(pathname, hash) {
  if (pathname?.startsWith('/search/image') && hash?.startsWith('#uri=')) {
    return `/search/image/index?owc=${encodeURIComponent(hash?.split('#uri=')?.[1])}`
  }
  if (pathname?.startsWith('/search/image/index/uri=') || pathname?.startsWith('/search/image/uri=')) {
    return `/search/image/index?owc=${encodeURIComponent(pathname.split('=')?.[1] || '')}`
  }
  return ''
}

/**
 * Remove the hash from the following URL in the film viewer:
 *   `/ark:/61903/3:1:3QS7-L9WZ-399R#uri=https%3A%2F%2Ffamilysearch.org%2Frecapi%2Fsord%2Fwaypoint%2F9R74-W38%3A790104201%3Fcc%3D2000219`
 * @param {string} pathname - The pathname of the current URL.
 * @param {string} hash - The hash of the current URL.
 * @returns {string} - The redirect URI for the film Viewer
 *   (e.g. `/ark:/61903/3:1:3QS7-L9WZ-399R`).
 */
function getFilmViewerWaypointArkRedirectUri(pathname, hash) {
  if (pathname?.startsWith('/ark:/61903/3:') && hash?.startsWith('#uri=')) {
    return pathname
  }
  return ''
}

/**
 * Get a good pathname for the bad opened waypoints link in the film viewer (remove owc param).
 * E.g. /ark:/61903/3:1:3QS7-L99S-6HL7?owc=waypoints&wc=HCXH-2NL%3A13684401%2C13684402%2C13936101&cc=1321742
 *   -> /ark:/61903/3:1:3QS7-L99S-6HL7?wc=HCXH-2NL%3A13684401%2C13684402%2C13936101&cc=1321742
 * This is from a bug in the old film viewer where the owc param was set to 'waypoints'. This function removes the owc
 * param in that case. (See https://fhjira.churchofjesuschrist.org/browse/FSS-10225)
 * @param {string} pathname - The pathname of the current URL.
 * @param {string} owcParam - The owc query param.
 * @param {string} search - The search query.
 * @returns {string} - The pathname for the bad opened waypoints link in the film viewer
 */
function getFilmViewerBadOpenedWaypointsPathname(pathname, owcParam, search) {
  if (
    owcParam === 'waypoints' &&
    (pathname.startsWith('/search/image/') ||
      pathname.startsWith('/ark:/61903/3:1:') ||
      pathname.startsWith('/ark:/61903/3:2:'))
  ) {
    const searchParams = new URLSearchParams(search)
    searchParams.delete('owc')
    return { pathname, search: `${searchParams.toString() ? `?${searchParams.toString()}` : ''}` }
  }
  return ''
}

/**
 * Remove the wc param if there is also an owc param in the URL.
 * @param {string} pathname - The pathname of the current URL.
 * @param {string} owcParam - The owc query param.
 * @param {string} wcParam - The wc query param.
 * @param {string} search - The search query.
 * @returns {string} - The pathname for the bad waypoint context in the film viewer
 */
function getFilmViewerExtraneousWaypointContext(pathname, owcParam, wcParam, search) {
  if (owcParam !== 'waypoints' && owcParam && wcParam) {
    const searchParams = new URLSearchParams(search)
    searchParams.delete('wc')
    return { pathname, search: `${searchParams.toString() ? `?${searchParams.toString()}` : ''}` }
  }
  return ''
}

/**
 * Get the redirect URL for the open waypoint chooser.
 * This happens when the owc query param has a partial path.
 *   e.g. /search/image/index?owc=collection/2351982/waypoints -> /search/image/index?owc=https://www.familysearch.org/recapi/collections/2351982/waypoints
 * JIRA: https://fhjira.churchofjesuschrist.org/browse/FSS-10372
 * @param {string} pathname - The pathname of the current URL.
 * @param {string} owcParam - The owc query param.
 * @param {string} search - The search query.
 * @returns {string} - The redirect URL for the open waypoint chooser
 */
function getRedirectOpenWaypointChooser(pathname, owcParam, search) {
  if (decodeURIComponent(owcParam).startsWith('collection/')) {
    const searchParams = new URLSearchParams(search)
    const fixedOwcParam = encodeURIComponent(
      `${envUrl}/service/cds/recapi/collections/${decodeURIComponent(owcParam).split('collection/')[1]}`
    )
    searchParams.set('owc', fixedOwcParam)
    return { pathname, search: `${searchParams.toString() ? `?${searchParams.toString()}` : ''}` }
  }
  return ''
}
