import React from 'react'
import { useTranslation } from 'react-i18next'
import { css } from '@emotion/core'
import { Paragraph, useContainerWidth, Separator, Button, Row, LayoutBand, H3 } from '@fs/zion-ui'
import UnknownErrorImg from './images/UnknownError.svg'

const bodyCss = css`
  text-align: center;
`
/**
 * Unknown Error shows our generic error page with a Something Went Wrong search results message. It has a reload the page button that reloads the page
 * @param {JSX.Element|string} [heading] - custom heading to override our generic one
 * @param {JSX.Element|string} [message] - custom error message to override our generic one
 * @param {string} pageType - generic, sourceLinker, searchResults - shows different information for each page type
 * @param {boolean} hideImage - A boolean to determine when to show the error image.
 * @param {boolean} hideReloadButton - A boolean to determine when to show the reload button.
 * @returns {JSX.Element}
 * @constructor
 */
export default function UnknownError({
  heading,
  message,
  pageType = 'generic',
  hideImage = false,
  hideReloadButton = false,
}) {
  const atWidth = useContainerWidth()
  const isSmallerScreen = atWidth({ default: true, md: false }) // hide image
  const { t } = useTranslation()

  let errorMessage = t(
    'search.results.error.body',
    'Unfortunately, something went wrong, and we are unable to display the search results. Try refreshing the page, or come back later.'
  )
  if (pageType === 'sourceLinker') {
    errorMessage = t(
      'search.sourceLinker.error.body',
      'Unfortunately, something went wrong, and we are unable to display source linker. Try refreshing the page, or come back later.'
    )
  } else if (pageType === 'generic') {
    errorMessage = t(
      'search.generic.error.body',
      'Unfortunately, something went wrong. Try refreshing the page, or come back later.'
    )
  }
  const noImageSepSize = isSmallerScreen ? 'md' : 'xxl'
  const imageSepSize = isSmallerScreen ? 'sm' : 'xl'
  return (
    <LayoutBand size="xs" data-testid="resultsError">
      <Separator size={hideImage ? noImageSepSize : imageSepSize} />
      {!isSmallerScreen && !hideImage && (
        <Row alignX="center">
          <img src={UnknownErrorImg} alt={t('search.error.imageAlt', 'Something Went Wrong')} />
        </Row>
      )}
      <Row alignX="center">
        <div css={bodyCss}>
          <H3>{heading || t('search.error.header', 'Something Went Wrong')}</H3>
        </div>
      </Row>
      <Row alignX="center">
        <div css={bodyCss}>
          <Paragraph>{message || errorMessage}</Paragraph>
        </div>
      </Row>
      <Separator size="xxs" />
      <Row alignX="center">
        {!hideReloadButton && (
          <Button
            emphasis="medium"
            onClick={() => {
              window.location.reload()
            }}
          >
            {t('search.notFound.optionReload', 'Reload the Page')}
          </Button>
        )}
        <BottomButtons />
      </Row>
    </LayoutBand>
  )
}

export function BottomButtons() {
  const { t } = useTranslation()
  return (
    <Row>
      <Button emphasis="medium" to="/" external>
        {t('gen-details.error.returnToHomePage', 'FamilySearch Home Page')}
      </Button>
    </Row>
  )
}
