import { useLocation, useQueryParams } from '@fs/zion-router'
import { baseUrl } from '@fs/zion-config'

export const DEPRECATED_PATHS = [
  '/search/catalog-search',
  '/catalog-search/',
  '/catalog/search/',
  '/eng/library/fhlc/',
  '/eng/library/fhlcatalog/',
  '/eng/library/fhlc/frameset_fhlc.asp',
]

/**
 * Custom hook to get the redirect URLs for catalog
 * These are used to support legacy links from bifrost e.g.
 * https://familysearch.org/search/catalog/show?uri=http://catalog-search-api:8080/www-catalogapi-webservice/item/476080
 * @returns {Object} - The redirect URLs for catalog details.
 */
export default function useCatalogRedirects() {
  const { pathname } = useLocation()
  const {
    query: { uri: uriParam },
  } = useQueryParams()

  let catalogShowRedirectUrl = ''
  let catalogDeprecatedPathUrl = ''

  // return early if not a film viewer URL
  const isCatalogUrl = pathname.startsWith('/search/catalog')
  if (isCatalogUrl) {
    catalogShowRedirectUrl = getCatalogShowRedirectUrl(uriParam, pathname)
  }
  catalogDeprecatedPathUrl = getCatalogDeprecatedPathUrl(pathname)

  return { catalogShowRedirectUrl, catalogDeprecatedPathUrl }
}

/**
 * Get the redirect URL for catalog details via the catalog/show path when a uri param is specified.
 * @param {string} uriParam - The URI query param ('http://catalog-search-api:8080/www-catalogapi-webservice/item/476080)
 * @param {string} pathname - The current pathname (e.g. '/search/catalog/show').
 * @returns {string} - The redirect URL for the catalog details (e.g. `/search/catalog/476080`).
 */
function getCatalogShowRedirectUrl(uriParam, pathname) {
  return pathname === '/search/catalog/show' ? `/search/catalog/${parseUri(uriParam)}` : ''
}

function getCatalogDeprecatedPathUrl(pathname) {
  if (!DEPRECATED_PATHS.includes(pathname.toLowerCase())) return ''
  const uri = new URL(`${baseUrl}/search/catalog`)
  const oldURL = baseUrl + pathname
  uri.searchParams.append('oldlink', oldURL)

  return `${uri.pathname}${uri.search}` || ''
}

function parseUri(uri) {
  if (!uri) return ''
  return decodeURIComponent(uri)
    .split('/')
    .pop()
}
