import axios from '@fs/zion-axios'
import { getSessionId } from '@fs/zion-session'
import { i18n } from '@fs/zion-locale'
// eslint-disable-next-line you-dont-need-lodash-underscore/clone-deep
import cloneDeep from 'lodash.clonedeep' // leaving cloneDeep in place for now to maintain consistency rather than split between native and library as structuredClone does not support cloning symbols which we do in RFV

/**
 * Remove null values from an object
 * @param {Object} obj Object to remove null values from
 * @returns {Object} Object with null values removed
 */
const removeNulls = (obj) => {
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null) {
      newObj[key] = obj[key]
    }
  })
  return newObj
}

/*
 * TODO: Make sure the page always fails gracefully
 * Our team typically handles errors at the UI level (useEffects that call these functions, usually) and doesn't catch errors in here, just
 * to re-throw them. How useful is it, really? If we want to fail gracefully for the users, the UI seems like the right place to handle it.
 * https://github.com/fs-webdev/search-react/pull/3059#discussion_r1287752297
 */

/**
 * Get image data from the search-filmdata API
 * @param {string} url ARK
 * @param {string} cat Catalog
 * @param {AbortController} abortController AbortController
 * @returns {Promise<any>} Film data
 */
export async function getImageData({ url, cat, abortController }) {
  // keep query params so that the waypoint context works as expected
  const imageURL = `${url.replace(/http:\/\/localhost:500[0-9]/, 'https://www.familysearch.org')}`
  return axios.post(
    '/search/filmdatainfo/image-data',
    {
      type: 'image-data',
      args: {
        imageURL,
        locale: i18n.language,
        state: removeNulls({
          cat,
          catalogContext: cat,
          imageOrFilmUrl: '',
          selectedImageIndex: -1,
          viewMode: 'i',
        }),
      },
    },
    { signal: abortController?.signal }
  )
}

/**
 * Get film data from the search-filmdata API. This data is only called out for if the intiial 'image-data' type
 * response had 12 or more persons index entries (this call gets the rest of them).
 * @param {Object} imageDataResponse - response from the search-filmdata API
 * @param {string} cat - Catalog
 * @param {AbortController} abortController - AbortController
 * @returns {Promise<Object>} - response from the search-filmdata API
 */
export async function getImageIndexData({ imageDataResponse, cat, abortController }) {
  const personIdsToRemove = imageDataResponse?.data?.records?.flatMap?.((r) => r?.persons)?.map?.((p) => p?.id)
  try {
    const response = await axios.post(
      '/search/filmdatainfo/image-data-indexed-records',
      {
        type: 'image-data-indexed-records',
        args: {
          imageData: removePersonData(imageDataResponse.data, personIdsToRemove),
          locale: i18n.language,
          state: removeNulls({
            cat,
            catalogContext: cat,
            imageOrFilmUrl: '',
            selectedImageIndex: -1,
            viewMode: 'i',
          }),
        },
      },
      { signal: abortController?.signal }
    )
    // merge back in the original persons data based on the person id
    response?.data?.records?.forEach?.((record) => {
      record?.persons?.forEach?.((person) => {
        // if the only property is id, then it's a placeholder and we should replace it with the original person data
        if (Object.keys(person).length === 1 && person.id) {
          const originalPerson = imageDataResponse?.data?.records
            ?.flatMap?.((r) => r?.persons)
            ?.find?.((p) => p?.id === person?.id)
          if (originalPerson) {
            Object.assign(person, originalPerson)
          }
        }
      })
    })

    return Promise.resolve(response)
  } catch (e) {
    // TODO: this should no longer happen, verify 413s are not happening with Splunk and remove this
    if (e?.response?.status === 413) {
      /*
       * HTTP status 413 - payload too large, fall back to initial imageDataResponse.
       * GenealogyBank image that used to have this problem:
       *   https://www.familysearch.org/ark:/61903/3:1:3Q9M-CSLQ-CS9X-4
       */
      return Promise.resolve(imageDataResponse)
    }
    console.error('Error fetching image index data', e)
    return Promise.reject(e)
  }
}

/**
 * Get waypoint data from the search-filmdata API
 * @param {string} url ARK
 * @param {string} dgsNum DGS number
 * @param {AbortController} abortController AbortController
 * @returns {Promise<any>} Waypoint data
 */
export async function getWaypointData({ url, dgsNum, abortController }) {
  return axios.post(
    '/search/filmdatainfo/waypoint-data',
    {
      type: 'waypoint-data',
      args: {
        dgsNum,
        waypointURL: `${url.replace(/https:\/\/familysearch.org/, 'https://www.familysearch.org')}`,
        locale: i18n.language,
        state: {
          imageOrFilmUrl: '',
          selectedImageIndex: -1,
          viewMode: 'i',
        },
      },
    },
    { signal: abortController?.signal }
  )
}

/**
 * Get film data from the search-filmdata API
 * @param {string} dgsNum DGS number
 * @param {string} cat Catalog
 * @param {string} ark ARK url
 * @param {number} index Image index
 * @param {boolean} loggedIn Is the user logged in
 * @param {AbortController} abortController AbortController
 * @returns {Promise<any>} Film data
 */
export async function getFilmData({ dgsNum, cat, url, index = -1, loggedIn, abortController }) {
  return axios.post(
    '/search/filmdatainfo/film-data',
    {
      type: 'film-data',
      loggedIn,
      sessionId: getSessionId(),
      args: {
        dgsNum,
        locale: i18n.language,
        state: {
          cat,
          imageOrFilmUrl: url,
          catalogContext: cat,
          viewMode: 'i',
          selectedImageIndex: index,
        },
      },
    },
    { signal: abortController?.signal }
  )
}

/**
 * Get film data from the search-filmdata API
 * @param {string} dgsNum DGS number
 * @param {string} cat Catalog
 * @param {string} imageIndex Image index
 * @param {boolean} loggedIn Is the user logged in
 * @param {AbortController} abortController AbortController
 * @returns {Promise<any>} Film data
 */
export async function getDgsFilmData({ dgsNum, cat, imageIndex, loggedIn, abortController }) {
  return axios.post(
    '/search/filmdatainfo/film-data',
    {
      type: 'film-data',
      loggedIn,
      sessionId: getSessionId(),
      args: {
        dgsNum: `${dgsNum}`,
        state: {
          i: imageIndex,
          cc: cat,
          imageOrFilmUrl: `/search/film/${dgsNum}`,
          collectionContext: cat,
          viewMode: 'g',
          selectedImageIndex: imageIndex,
        },
        locale: i18n.language,
      },
    },
    { signal: abortController?.signal }
  )
}

/**
 * Remove person data from the records to prevent 413 payload too large error (persons are not needed for the
 * image index data call)
 * @param {Object} data - film data
 * @returns {Object} - data with persons removed from records (leaving only their ids so they can be re-merged later)
 */
function removePersonData(data, personIdsToRemove) {
  const clonedData = cloneDeep(data)
  // remove persons from the records (leaving only their ids so they can be re-merged later)
  clonedData?.records?.forEach?.((record) => {
    /*
     * Check that record is an object, because record can be a string like:
     *   https://www.familysearch.org/platform/records/records/H4PP-JWXG?flag=fsh&cc=5000375
     * From: https://www.familysearch.org/ark:/61903/3:1:3Q9M-CS8N-D3FZ-N?i=923&cat=731421
     */
    if (typeof record === 'object') {
      record.persons =
        record?.persons?.map?.((person) => {
          if (personIdsToRemove?.includes?.(person.id)) {
            return {
              id: person.id,
            }
          }
          return person
        }) ?? []
    }
  })

  return clonedData
}
