import { useEffect, useReducer } from 'react'
import zionDebug from '@fs/zion-debug'
import { useSyncedRef } from '@fs/zion-frontend-friends'
import { useHistory } from '@fs/zion-router'
import { useUser } from '@fs/zion-user'
import { getImageData, getWaypointData, getFilmData, getDgsFilmData, getImageIndexData } from './filmDataServices'
import filmDataReducer, {
  filmDataReducerInitialState,
  imageHasWaypoints,
  findCollectionResource,
} from './filmDataReducer'
import { FVError } from './FilmViewerErrorBoundary'

const debug = zionDebug('search:film-viewer:useFilmData')

// number of images viewed before waiting a bit before each image info call (to prevent too many API calls and Imperva blocking)
const NUM_VIEWED_IMAGES_BEFORE_WAITING_TO_REQUEST = 10
const WAIT_TIME_BEFORE_IMAGE_INFO_CALL = 1300 // helps prevent Imperva blocking
// if most recent call was less than this time ago, wait before making the next call to filmdatainfo
const WAIT_TIME_TO_NOT_THROTTLE = 5000

/*
 * Waits for a specified amount of time before resolving.
 * @param {number} waitMs - The amount of time to wait in milliseconds
 * @returns {Promise<void>} - A promise that resolves after the specified amount of time
 */
const timeout = async (waitMs) => new Promise((resolve) => setTimeout(resolve, waitMs))

/**
 * Hook to get data from the search-filmdata API and images (waypointed or not) for the image viewer/grid.
 *
 * There are three modes:
 *   1. ark: e.g. /ark:/61903/3:1:3QSQ-G9MT-86K2?wc=QZXP-5CT%3A790105301%2C796133301%2C796133302%2C952084901%3Fcc%3D2000219&cc=2000219
 *   2. dgs: e.g. /search/film/004139646?cc=2475025&mode=g&i=563
 *   3. waypoint-chooser: e.g. /search/image/index?owc=SKF6-829%3A1438741802%3Fcc%3D2331267
 *
 * @param {Object} object - The object to get
 * @param {string} object.ark - The ark from the object
 * @param {string} object.dgsNum - The dgsNum from the object
 * @returns film data and images for the image viewer/grid
 */
export default function useFilmData({ ark, dgsNum }) {
  const [state, dispatch] = useReducer(filmDataReducer, filmDataReducerInitialState)
  const stateRef = useSyncedRef(state)
  const { userLoading, signedIn: loggedIn } = useUser()
  const history = useHistory()

  useEffect(() => {
    if (userLoading) return () => {}

    const currentState = stateRef.current
    const mode = getMode({ ark, dgsNum })

    const search = new URLSearchParams(window.location.search)

    const { collectionContext } = currentState
    const catParam = search.get('cat') || collectionContext || search.get('cc')

    let isCancelled = false
    const abortController = new AbortController()

    if (mode === 'waypoint-chooser') {
      dispatch({ type: 'SET_MODE', mode, isCancelled })
      return () => {}
    }

    // In ark mode, call filmdatainfo with image-data and waypoint-data or film-data
    if (mode === 'ark') {
      if (currentState.imageData?.arkId !== ark || currentState?.imageData?.permissionsCheckImage) {
        debug(`getImageData for ark: ${ark} and url: ${window.location.href}`)
        dispatch({ type: 'SET_LOADING', mode, isCancelled })
        getArkModeData({
          state: currentState,
          dispatch,
          catParam,
          loggedIn,
          abortController,
          getIsCancelled: () => isCancelled,
        })
      } else {
        dispatch({ type: 'SET_MODE', mode, isCancelled })
      }
    }

    // If this is a DGS number (/search/film/004139646?cc=2475025&mode=g&i=563), we need to call filmdatainfo with type: film-data
    if (mode === 'dgs') {
      // If we don't have the film data for the current dgs, get it
      if (!currentState.dgsFilmData?.dgsNum) {
        const transitionToDgsArk = search.get('transitionToDgsArk')
        const imageIndexParam = search.get('i') ? parseInt(search.get('i'), 10) : 0

        // if there is no i param, set index to 0
        debug(`getDgsFilmData for effectiveDgsNum "${dgsNum}" and catParam: ${catParam}`)
        dispatch({ type: 'SET_LOADING', mode: 'dgs', isCancelled })
        getDgsModeData({
          dgsNum,
          transitionToDgsArk,
          history,
          catParam,
          dispatch,
          imageIndexParam,
          loggedIn,
          abortController,
          getIsCancelled: () => isCancelled,
        }).then((filmData) => {
          if (isCancelled) return
          checkPermissionsForDgsMode({
            filmData,
            dispatch,
            dgsNum,
            transitionToDgsArk,
            catParam,
            imageIndex: imageIndexParam,
            abortController,
            getIsCancelled: () => isCancelled,
          })
        })
      } else {
        dispatch({ type: 'SET_MODE', mode, isCancelled })
      }
    }

    // Cleanup function prevents React state updates and cancels current API calls
    // after FilmViewer is unmounted or if this useEffect is called again before the API calls are finished
    return () => {
      isCancelled = true
      abortController.abort()
    }
  }, [ark, dgsNum, history, loggedIn, stateRef, userLoading])

  // return the state function
  return [state, dispatch]
}

/**
 * Find out what mode we're in.
 * @param {*} object the object to get the mode from
 * @param {*} object.ark the ark from the object
 * @param {*} object.dgsNum the dgsNum from the object
 * @returns the mode string ('ark', 'dgs', or 'waypoint-chooser')
 */
function getMode({ ark, dgsNum }) {
  if (ark) return 'ark' // e.g. /ark:/61903/3:1:3QSQ-G9MT-86K2?wc=QZXP-5CT%3A790105301%2C796133301%2C796133302%2C952084901%3Fcc%3D2000219&cc=2000219
  if (dgsNum) return 'dgs' // e.g. /search/film/004139646?cc=2475025&mode=g&i=563
  return 'waypoint-chooser' // e.g. /search/image/index?owc=SKF6-829%3A1438741802%3Fcc%3D2331267
}

// Makes sure ark matches the following format:
// `3:{1 or 2}:{4 alpha-numeric char}-{up to 4 alpha-numeric char, must have at least one char}-{up to 4 alpha-numeric char}-{up to 4 alpha-numeric char}
//                                                                                            ^ Everything is optional past this point
// Anything else will be rejected. Proper formate ex. '3:1:33SQ-GYB9-9218' OR '3:1:33SQ-GYB' OR '3:1:3Q9M-CSSH-KSZT-X'
// More context: https://fhconfluence.churchofjesuschrist.org/display/ARCH/Archival+Resource+Keys+-+Ark, https://fhconfluence.churchofjesuschrist.org/display/ARCH/Image+Ark+APID+JEncoding+scheme
export const arkTestRegex = /^3:\d{1,2}:[A-Za-z0-9]{4}-[A-Za-z0-9]{1,4}-?[A-Za-z0-9]{0,4}-?[A-Za-z0-9]{0,1}$/i

export const arkRegex = /(3:[1-2]:[^?/]*)/g
/**
 * Extracts the ark from a URL.
 * @param {string} url the URL to extract the ark from
 * e.g. http://localhost:5001/ark:/61903/3:1:3QSQ-G9MT-86K2?wc=QZXP-5CT%3A790105301%2C796133301%2C796133302%2C952084901%3Fcc%3D2000219&cc=2000219 -> 3:1:3QSQ-G9MT-86K2
 * @returns the ark
 */
export function extractArk(url) {
  const match = url?.match(arkRegex)
  return match && match[0]
}

/**
 * Fetches ImageData/WaypointData/FilmData from the search-filmdata API for ark mode and updates the reducer with the fetched data
 * @param {Object} state the state from the reducer
 * @param {Function} dispatch the dispatch function from the reducer
 * @param {string} catParam the cat parameter
 * @param {boolean} loggedIn true if the
 * @param {Object} abortController - Signal watcher for the API call
 * @param {Function} getIsCancelled - Gets a bool used to cancel state updates
 * @returns
 */
async function getArkModeData({ state, dispatch, catParam, loggedIn, abortController, getIsCancelled }) {
  /*
   * If last image info call was less than WAIT_TIME_TO_NOT_THROTTLE ms ago, and we have viewed more than
   * NUM_VIEWED_IMAGES_BEFORE_WAITING_TO_REQUEST images, wait a bit before making the next call
   */
  if (
    state.viewedImages.size > NUM_VIEWED_IMAGES_BEFORE_WAITING_TO_REQUEST &&
    Date.now() - state.lastImageInfoTimestamp < WAIT_TIME_TO_NOT_THROTTLE
  ) {
    await timeout(WAIT_TIME_BEFORE_IMAGE_INFO_CALL)
    if (getIsCancelled()) return
  }
  dispatch({ type: 'SET_LAST_IMAGE_INFO_TIMESTAMP', timestamp: Date.now() })
  const { data: imageData } = await getImageData({
    url: window.location.href,
    abortController,
  })
    .then((response) => {
      /*
       * If the image is waypointed, we need to call filmdatainfo with type: waypoint-data
       * This happens asynchronously with the next call for image-data-indexed-records (if needed)
       */
      const initialImageData = response?.data
      const isWaypointed = imageHasWaypoints(initialImageData)
      const waypointUrl = findCollectionResource(initialImageData?.meta)?.about

      /*
       * Remove the wc param from the url if we don't have waypoints (there may be waypoints upon refresh after this).
       * This is to handle bad wc values that come from old pal links (e.g. https://familysearch.org/pal:/MM9.3.1/TH-1961-31167-9801-89?cc=2037985&wc=M994-7BS:n2076896404).
       * If there is a bogus wc value, it will be removed from the url and the page will refresh without it.
       * This is to fix the problem where a bogus wc param causes waypoints to not come back. See FSS-10220
       */
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.get('wc') && !isWaypointed && !waypointUrl) {
        searchParams.delete('wc')
        const url = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`
        window.history.replaceState(null, null, url)
        // refresh (does not add to history)
        window.location.reload('cheese')
      }

      const waypointDataNeedsUpdate =
        !state?.hasEnteredDgsMode &&
        (waypointUrl !== state?.waypointData?.waypointURL ||
          (initialImageData?.dgsNum !== state?.waypointData?.dgsNum && state?.waypointData?.dgsNum !== 'authError'))

      if (isWaypointed && waypointDataNeedsUpdate) {
        dispatch({ type: 'SET_LOADING_WAYPOINTS', isCancelled: getIsCancelled() })

        getWaypointData({
          url: waypointUrl,
          dgsNum: initialImageData?.dgsNum,
          abortController,
        })
          .then((waypointData) => {
            dispatch({ type: 'SET_WAYPOINT_DATA', waypointData: waypointData?.data, isCancelled: getIsCancelled() })
          })
          .catch((error) => {
            console.error('Failed to get waypoint data from filmdata', error)
            dispatch({ type: 'ERROR', error, isCancelled: getIsCancelled() })
          })
      }

      /*
       * If there are 12 or more records in the response of the image-data call, then we need to get the rest of
       * the records by making a call for image-data-indexed-records, which is just like image-data, but has more records.
       *  Also, if component has unmounted, we don't want to make this 2nd call.
       */
      if (response?.data?.records?.length < 12 || getIsCancelled()) return Promise.resolve(response)
      return getImageIndexData({
        imageDataResponse: response,
        cat: catParam,
        abortController,
      })
    })
    .catch((error) => {
      if (!error.response?.status === 403) {
        console.error('Failed to get image data from filmdata', error)
      }
      dispatch({ type: 'ERROR', error, isCancelled: getIsCancelled() })

      // Returning meta data so we can render citations and waypoint-data
      const imageMeta = error?.response?.data?.error?.imageMeta
      return { data: { ...state.imageData, meta: imageMeta } }
    })

  if (getIsCancelled()) return

  dispatch({ type: 'SET_IMAGE_DATA', imageData, isCancelled: getIsCancelled() })

  const isWaypointed = imageHasWaypoints(imageData)

  // If the image is not waypointed, we need to call filmdatainfo with type: film-data
  // only get new film data if we don't already have it for the current dgs
  const filmDataNeedsUpdate = state?.dgsNum !== imageData?.dgsNum
  if (!isWaypointed && imageData?.dgsNum && filmDataNeedsUpdate) {
    const filmData = await getFilmData({
      url: window.location.href,
      dgsNum: imageData.dgsNum,
      cat: catParam,
      loggedIn,
      abortController,
    }).catch((error) => {
      console.error('Failed to get film data from filmdatainfo call of type film-data', error)
      dispatch({ type: 'ERROR', error, isCancelled: getIsCancelled() })
    })

    dispatch({ type: 'SET_FILM_DATA', filmData: filmData?.data, isCancelled: getIsCancelled() })
  }
}

/**
 * Check permissions of current focused image. This will cause the permissions overlay to appear if the user does not
 * have access to the current image in the grid.
 * @param {Object} filmData - The film data
 * @param {Function} dispatch - Dispatcher for the reducer
 * @param {number} imageIndex - The index of the image in the grid
 * @param {string} transitionToDgsArk - The ark to transition to (this is optional and only used when transitioning
 *   from ark to dgs so we know which ark to use–it is used to load other collections the image belongs to, as seen
 *   in the film number dropdown from dgs mode)
 * @param {string} catParam - The cat parameter
 * @param {Object} abortController - Signal watcher for the API call
 * @param {Function} getIsCancelled - Gets a bool used to cancel state updates
 */
async function checkPermissionsForDgsMode({
  filmData,
  dispatch,
  imageIndex,
  transitionToDgsArk,
  catParam,
  abortController,
  getIsCancelled,
}) {
  // unless film number was clicked while in ark mode, transitionToDgsArk is undefined, so we need to extract the ark from the image data using the i param
  const ark = transitionToDgsArk || extractArk(filmData?.images?.[Math.max(0, parseInt(imageIndex, 10) ?? -1)])
  const url = filmData.templates.dzTemplate.replace('{id}', `${ark}/image.xml`).replace('/{image}', '')

  // TODO: get all image info data and store in state so that immediately clicking the image does not require another call
  await getImageData({ url, catParam, abortController })
    .then(({ data: imageData }) => {
      // store the image data so the film viewer dropdown can use it to show collection links this image belongs to
      dispatch({
        type: 'SET_IMAGE_DATA',
        imageData: { ...imageData, permissionsCheckImage: true },
        isCancelled: getIsCancelled(),
      })
    })
    .catch((error) => {
      if (!error.response?.status === 403) {
        console.error('Failed to get image data from filmdatainfo call of type image-data', error)
      }
      dispatch({ type: 'ERROR', error, isCancelled: getIsCancelled() })
    })
}

/**
 * Fetches FilmData from the search-filmdata API for dgs mode and updates the reducer with the fetched data
 * @param {string} dgsNum - The dgsNum of the film
 * @param {string} transitionToDgsArk - The ark to transition to
 * @param {Object} history - The history object
 * @param {string} catParam - The cat parameter
 * @param {string} imageIndexParam - The image index parameter
 * @param {Function} dispatch - Dispatcher for the reducer
 * @param {boolean} loggedIn - True if the user is logged in
 * @param {Object} abortController - Signal watcher for the API call
 * @param {Function} getIsCancelled - Gets a bool used to cancel state updates
 * @returns {Promise<Object>} - The fetched film data (used by getImageDataForDgsMode to check permissions)
 */
async function getDgsModeData({
  dgsNum,
  transitionToDgsArk,
  history,
  catParam,
  imageIndexParam,
  dispatch,
  loggedIn,
  abortController,
  getIsCancelled,
}) {
  const { data: dgsFilmData } = await getDgsFilmData({
    dgsNum,
    cat: catParam,
    imageIndexParam,
    loggedIn,
    abortController,
  }).catch((error) => {
    console.error('Failed to get DGS film data from filmdatainfo call of type film-data', error)
    if (error.response?.status === 404) {
      dispatch({
        type: 'ERROR',
        error: new FVError({ type: 'filmNotFound' }),
        isCancelled: getIsCancelled(),
      })
      return {}
    }
    dispatch({ type: 'ERROR', error, isCancelled: getIsCancelled() })
    return {}
  })

  if (!Array.isArray(dgsFilmData?.images) || dgsFilmData.images.length === 0) {
    dispatch({
      type: 'ERROR',
      error: new FVError({ type: 'filmHasNoImages' }),
      isCancelled: getIsCancelled(),
    })
  }

  // stage 2: set the image index relative to the dgs (if coming from dgs dropdown)
  if (transitionToDgsArk) {
    const imageIndex = dgsFilmData?.images?.findIndex((image) => image?.includes(`/${transitionToDgsArk}/`))
    const searchParams = new URLSearchParams(history.location.search)
    if (catParam) {
      searchParams.set('cc', catParam)
    }
    searchParams.set('i', imageIndex)
    searchParams.delete('mode')
    searchParams.delete('transitionToDgsArk')
    const url = `/search/film/${dgsNum}?${searchParams.toString()}`
    history.replace(url)
  }

  dispatch({ type: 'SET_DGS_FILM_DATA', dgsFilmData, dgsNum, isCancelled: getIsCancelled() })

  return dgsFilmData
}
