import { useFeatureFlag } from '@fs/zion-flags'
import { useLocation, useQueryParams } from '@fs/zion-router'

/**
 * Fix old hr year params
 * @param {object} query
 * @param {object} newQuery
 * @param {boolean} redirectNeeded
 * @param {string} yearType
 * @returns {undefined}
 */
function changeYearParamKey(query, newQuery, redirectNeeded = false, yearType) {
  if (!query || !newQuery || !yearType) {
    throw new Error('Missing required parameters')
  }

  const yearKey = `q.${yearType}Year`
  if (!(yearKey in query)) return redirectNeeded

  return processYearValue(query, newQuery, yearKey, yearType, redirectNeeded)
}

/**
 * create new year query key and set the key's value
 * @param {object} query query object from the original URL
 * @param {object} newQuery copy of the original query object
 * @param {string} yearKey should look like "q.<yearType>Year"
 * @param {string} yearType birth, death, marriage, residence, etc.
 * @param {boolean} redirectNeeded
 * @returns {undefined}
 */
function processYearValue(query, newQuery, yearKey, yearType, redirectNeeded) {
  const needsLike = ['birth', 'marriage', 'death'].includes(yearType)
  const yearKeyBase = `q.${yearType}${needsLike ? 'Like' : ''}Date`
  const yearValue = query[yearKey]

  delete newQuery[yearKey]
  setDateRange(newQuery, yearKeyBase, yearValue)

  redirectNeeded = true
  return redirectNeeded // Redirect is needed since we processed a year
}

/**
 * parse and set year query from and to values
 * @param {object} newQuery copy of the original query object
 * @param {string} yearType birth, death, marriage, residence, etc.
 * @param {string} yearKeyBase should look like "q.<yearType>LikeDate" or "q.<yearType>Date"
 * @returns {undefined}
 */
function setDateRange(newQuery, yearKeyBase, yearValue) {
  if (typeof yearValue !== 'string' || !yearValue.includes('-')) {
    newQuery[`${yearKeyBase}.from`] = yearValue
    return
  }

  // if the query has a "-" there may be two years in the query
  const [fromYear, toYear] = yearValue.split('-')
  newQuery[`${yearKeyBase}.from`] = fromYear
  if (fromYear !== toYear) {
    newQuery[`${yearKeyBase}.to`] = toYear
  }
}

/**
 * Fixes old hr search bifrost URL queries
 * @returns {string | undefined} url to redirect to or undefined if no redirect needed
 */
export default function useHrRedirects() {
  const { pathname } = useLocation()
  const { query } = useQueryParams()

  const hrRedirects = useFeatureFlag('search_hrRedirects')?.isOn
  if (!hrRedirects) {
    return undefined
  }

  const newQuery = JSON.parse(JSON.stringify(query))
  let redirectNeeded = null

  const fixYearParam = (yearType) => changeYearParamKey(query, newQuery, redirectNeeded, yearType)

  // birth year
  // bad URL: https://www.familysearch.org/search/record/results?collection.id=1438024&count=20&localeSubcountryName=Michigan&q.birthYear=1867-1868~&q.givenname=Winnie&q.surname=Kirkendall
  // redirected URL: https://www.familysearch.org/en/search/record/results?collection.id=1438024&count=20&localeSubcountryName=Michigan&q.surname=Kirkendall&q.givenName=Winnie&q.birthLikeDate.from=1867&q.birthLikeDate.to=1868
  fixYearParam('birth')

  // marriage year
  // bad URL: https://www.familysearch.org/en/search/record/results?count=20&m.defaultFacets=on&m.facetNestCollectionInCategory=on&m.queryRequireDefault=on&offset=0&q.birthPlace=England~&q.birthYear=1777-1777~&q.deathPlace=West%20Virginia~&q.deathYear=1867-1867~&q.givenname=henry~&q.marriagePlace=West%20Virginia~&q.marriageYear=1816-1816~&q.spouseGivenname=elizabeth~&q.spouseSurname=rhodes~&q.surname=price~
  // redirected URL: https://www.familysearch.org/en/search/record/results?count=20&m.defaultFacets=on&m.facetNestCollectionInCategory=on&m.queryRequireDefault=on&q.birthPlace=England&q.deathPlace=West+Virginia&q.marriagePlace=West+Virginia&q.spouseSurname=rhodes&q.surname=price&q.birthLikeDate.from=1777&q.birthLikeDate.to=1777&q.marriageLikeDate.from=1816&q.marriageLikeDate.to=1816&q.deathLikeDate.from=1867&q.deathLikeDate.to=1867&q.givenName=henry&q.spouseGivenName=elizabeth
  fixYearParam('marriage')

  // death year
  // bad URL: https://www.familysearch.org/en/search/record/results?count=20&offset=4900&q.surname=pollard&c.marriageLikePlace1=on&f.marriageLikePlace0=10&q.deathYear=1715-1715~
  // redirected URL: http://localhost:5006/en/search/record/results?count=20&offset=4900&q.surname=pollard&q.deathLikeDate.to=1715
  fixYearParam('death')

  // residence year
  // bad URL: https://www.familysearch.org/en/search/record/results?count=20&m.defaultFacets=on&m.facetNestCollectionInCategory=on&m.queryRequireDefault=on&q.residenceDate.from=1885&q.residenceDate.to=1885&q.residencePlace=Lancaster%2C%20Nebraska%2C%20United%20States&q.residencePlace.exact=on&q.residenceYear=1885-1885~
  // redirected URL: https://www.familysearch.org/en/search/record/results?count=20&m.defaultFacets=on&m.facetNestCollectionInCategory=on&m.queryRequireDefault=on&q.residencePlace=Lancaster%2C+Nebraska%2C+United+States&q.residencePlace.exact=on&q.residenceDate.from=1885&q.residenceDate.to=1885
  fixYearParam('residence')

  Object.entries(newQuery).forEach(([key, value]) => {
    // replace all tildes in the values of the query object
    // bad URL: https://www.familysearch.org/en/search/record/results?count=20&offset=4900&q.surname=pollard&c.marriageLikePlace1=on&f.marriageLikePlace0=10&q.deathYear=1715-1715~
    // redirected URL: https://www.familysearch.org/en/search/record/results?Fcount=20&q.surname=pollard&c.marriageLikePlace1=on&f.marriageLikePlace0=10&q.deathLikeDate.to=1715&q.deathLikeDate.from=1715
    if (value.includes('~')) {
      redirectNeeded = true
      newQuery[key] = newQuery[key].replaceAll('~', '')
    }

    // any given name (spouse, parent, etc.)
    // bad URL: https://www.familysearch.org/en/search/record/results?count=20&m.defaultFacets=on&m.facetNestCollectionInCategory=on&m.queryRequireDefault=on&offset=0&q.birthPlace=Kentucky%2C+United+States%22&q.deathPlace=Indiana&q.surname=Bohannon+Sears%22&q.givenname=Lula&q.birthLikeDate.from=1915&q.birthLikeDate.to=1919&q.deathLikeDate.from=1980&q.deathLikeDate.to=2012&q.spouseGivenname=Gertrude
    // redirected URL: https://www.familysearch.org/en/search/record/results?count=20&m.defaultFacets=on&m.facetNestCollectionInCategory=on&m.queryRequireDefault=on&q.birthPlace=Kentucky%2C+United+States%22&q.deathPlace=Indiana&q.surname=Bohannon+Sears%22&q.birthLikeDate.from=1915&q.birthLikeDate.to=1919&q.deathLikeDate.from=1980&q.deathLikeDate.to=2012&q.givenName=Lula&q.spouseGivenName=Gertrude
    if (key.includes('ivenname')) {
      redirectNeeded = true
      const oldValue = newQuery[key]
      delete newQuery[key]
      newQuery[key.replace('ivenname', 'ivenName')] = oldValue
    }
  })

  // redirect if needed
  if (redirectNeeded) {
    delete newQuery.offset
    const newURL = `${pathname}?${new URLSearchParams(newQuery).toString()}`
    return newURL
  }
  return undefined
}
