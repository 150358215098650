import { showEx } from '@fs/zion-experiments'
import zionDebug from '@fs/zion-debug'

const debug = zionDebug('search:serverExSupport')

/**
 * This is a global interceptor for network requests. The purpose is to add server experiment support for requests
 * that are made by the browser - not by the react application.
 * @param {[String]} exList - ordered list of experiment names
 * @param {[String]} valueList - ordered list of the values to be added for experiments in the exList
 */
export default function addServerExSupport(exList, valueList) {
  if (window.oldOpen) {
    // Make sure we only add this one time. We don't want a bunch if redundant interceptors.
    console.error(`ERROR: addServerExSupport called multiple times`)
    return
  }

  debug('Hooking XMLHttpRequest.open method')
  window.oldOpen = XMLHttpRequest.prototype.open

  XMLHttpRequest.prototype.open = function hookedXmlHttpRequest(...args) {
    debug(`serverExSupport.hookedXmlHttpRequest running`)
    const res = window.oldOpen.apply(this, args)

    // Determine if this is a FS domain we want experiments on
    const url = new URL(args[1], 'http://localhost:5000')
    if (isFSServerExHost(url.host)) {
      // Add server experiments here ------------------------
      const serverFeatureKey = 'x-fs-feature-tag'
      exList.forEach((exName, index) => {
        if (showEx(exName)) {
          debug(`Adding server experiment: ${valueList[index]} to ${args[1]}`)
          this.setRequestHeader(serverFeatureKey, valueList[index])
        }
      })
    } else {
      debug(`Skipping server experiments for ${args[1]}`)
    }
    return res
  } // XMLHttpRequest.prototype.open()
} // addServerExSupport()

/**
 * Check to see if the host is one we should send server experiments to.
 *
 * @param {String} host - the host portion of the URL.
 * @returns {boolean} True if the host should include server experiments.
 */
function isFSServerExHost(host) {
  // exclude tracking domain
  if (host.startsWith('om.familysearch.org')) {
    return false
  }
  return host.startsWith('/') || host.indexOf('familysearch') !== -1 || host.indexOf('localhost') !== -1
}
