import React, { StrictMode, useState, Suspense, useContext } from 'react'
import ReactDOM from 'react-dom'
import '@fs/react-scripts/polyfills'
import * as Sentry from '@sentry/browser'
import { essentialLocales, setAppLocales } from '@fs/zion-locale'
import { ConditionalFooterContext } from '@fs/zion-record-search'
import Root from '@fs/zion-root'
import { Router, useLocation } from '@fs/zion-router'
import { sentryDSN, mergeSentryConfig, appPath } from '@fs/zion-config'
import { CaptureConsole, ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import { Integrations as TracingIntegrations } from '@sentry/tracing'

import { AppLayout } from '@fs/zion-ui'
import { FeatureFlagsProvider, useFeatureFlag } from '@fs/zion-flags'
import { NoticeLoading } from '@fs/zion-icon'
import { BrightspotProvider } from '@fs/zion-cms'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './locales'
import addServerExSupport from './lib/serverExSupport'
import DarkModeProvider, { DarkModeContext } from './DarkModeProvider'

// Enable local feature flag development using the file "dev.flags.js"
// If that file exports anything truthy it will initialize feature flags in localhost mode
// and use the flags defined in that file, instead of flags defined in split.
const mockFlags = process.env.NODE_ENV === 'development' ? import('./dev.flags').then((m) => m.default) : undefined

// initialize Sentry for the app
if (sentryDSN) {
  // pass Sentry overrides to mergeSentryConfig
  Sentry.init(
    mergeSentryConfig({
      dsn: sentryDSN,
      denyUrls: [
        // Ignore errors from saved pages
        'file:///',
      ],
      ignoreErrors: [
        // This error happens on beta when we try to use a restricted digitally born image
        "getBornDigitalContent can't identify digital-content because we caught err",
        // Happens in WebKit, particularly iOS Mail. Unresolved WebKit issue: https://bugs.webkit.org/show_bug.cgi?id=197050
        /UnknownError.*Indexed Database server.*/,
        // This error happens when a browser aborts a request
        'Request aborted',
        // We have some off-page dependencies that seem to fail occasionally
        /(fbq|gtag) is not defined/,
        /Can't find variable: (fbq|gtag)/,
        // This should be fixed in zion-flags v2.1.1, but we are somehow still getting the errors
        /No split available for [a-zA-Z0-9_]+/,
        // Caused by TrustArc banner. Jacob Wilden is our contact with TrustArc. Benign error that we have no control over
        /Cannot read properties of undefined \(reading 'c'\)/,
        /undefined is not an object \(evaluating 'd\.c'\)/,
        /d is undefined/,
        // Outlook notification error, benign and non-impactful
        /Non-Error promise rejection captured with value: Object Not Found Matching Id:(\d+), MethodName:/,
        // possible relationship issue in the Family Tree
        /duplicatePersonId found on SL page/,
        /circularRelationships found on SL page/,
      ],
      integrations: [
        new CaptureConsole({
          levels: ['error'],
        }),
        new TracingIntegrations.BrowserTracing(),
        new ExtraErrorDataIntegration({
          // Limit of how deep the object serializer should go. Anything deeper than limit will
          // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
          // a primitive value. Defaults to 3.
          depth: 4,
        }),
      ],
      sampleRate: 0.25,
      // This reduces how many transactions are sent to Sentry. search-react sends more transactions that other projects
      tracesSampleRate: 0.00005,
    })
  )
}

// Consistent scroll restoration behavior across browsers
window.history.scrollRestoration = 'auto'

setAppLocales(essentialLocales)

const RootWrapper = () => {
  const { theme } = useContext(DarkModeContext)
  const [showFooter, setShowFooter] = useState(true)
  const { pathname } = useLocation()
  const isGenealogies = pathname.startsWith('/ark:/61903/2:')
  const [useFatFooter, setUseFatFooter] = useState(false)

  return (
    <ConditionalFooterContext.Provider value={setShowFooter}>
      <Root theme={theme} analytics fullScreen={isGenealogies} footer={{ hidden: !showFooter, useFatFooter }}>
        <AppLayout fullWidth>
          <App setUseFatFooter={setUseFatFooter} />
        </AppLayout>
      </Root>
    </ConditionalFooterContext.Provider>
  )
}

const handledExternalRoutes = [
  // These routes look really weird because they get added to a regex and compiled in react-router's matchPath() function
  //  - thus the double slashes and arkPath match that don't seem to make sense
  // It's important that the whole path is included here because the regex adds a check for /, ?, or end of URL after this string

  // handle 1:1: arks locally, always
  '/ark\\:/61903/1\\:1\\::arkPath',

  // handle 2: arks only if the React genealogies details page is released
  '/ark\\:/61903/2\\::arkPath',
]

const RouterWrapper = () => {
  const addLangCodeToUrl = useFeatureFlag('shared_addLangCodeToUrl').isOn
  const base = appPath && addLangCodeToUrl ? new URL(appPath).pathname : '/'

  return (
    <Router basename={base} key={base} handledExternalRoutes={handledExternalRoutes}>
      <DarkModeProvider>
        <RootWrapper />
      </DarkModeProvider>
    </Router>
  )
}

const FrontierRoot = () => {
  return (
    <StrictMode>
      <Suspense fallback={<NoticeLoading />}>
        <FeatureFlagsProvider mockFlags={mockFlags}>
          <BrightspotProvider sites={['search-web']} hasCustomContent>
            <RouterWrapper />
          </BrightspotProvider>
        </FeatureFlagsProvider>
      </Suspense>
    </StrictMode>
  )
}

// We need to be able to set server side experiment headers for requests that are made by the browser.
// This global intercept is designed for that specific purpose.
addServerExSupport(['dasProxy'], ['das.prod.proxy,dz.prod.proxy'])

ReactDOM.render(<FrontierRoot />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
